<template>
    <div class="app">
		<header>
			<a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

			<p class="title">Profile Page</p>
		</header>

		<div v-if="!token" class="auth pt-4 pb-4">
			<router-link to="/register" class="button secondary">Register</router-link>
			<router-link to="/login" class="button">Login</router-link>
		</div>

		<div class="image-area" v-if="token">
			<div class="profile">
				<img :src="me.profile_url" alt="Profile">
			</div>

			<form id="form">
				<label for="picture" class="edit-icon"><i class="fal fa-pen"></i></label>
				<input type="file" name="picture" id="picture" hidden @change="updateProfilePicture">

				<button type="submit" id="submit-button" style="display: none"></button>
			</form>
		</div>

		<div class="container">
			<div v-if="token" class="loggedin mt-5 pt-5">
				<div class="user mb-4">
					<div class="name">
						<p>Nama</p>
						<p v-if="!edit">{{ me.name }}</p>

						<input type="text" class="edit-field" v-model="me.name" v-if="edit">
					</div>
					<div class="email">
						<p>Email</p>
						<p v-if="!edit">{{ me.email }}</p>

						<input type="text" class="edit-field" v-model="me.email" v-if="edit">
					</div>
					<div class="phone">
						<p>Nomor Telp</p>
						<p v-if="!edit">{{ me.phone }}</p>

						<input type="text" class="edit-field" v-model="me.phone" v-if="edit">
					</div>
				</div>

				<a class="a-edit mb-4" v-if="!edit" @click.prevent="edit = true">Edit Data</a>
				<button type="button" class="button mb-4" v-if="edit" @click="updateProfile">Save</button>
			</div>

			<div class="help">
				<h6 class="title">Help</h6>

				<router-link to="/kebijakanprivasi" class="first">
					<p>Kebijakan Privasi</p>
					<i class="fal fa-angle-right"></i>
				</router-link>

				<router-link to="/pengumpulan-informasi-pribadi">
					<p>Pengumpulan Informasi Pribadi</p>
					<i class="fal fa-angle-right"></i>
				</router-link>

				<router-link to="/syarat-dan-ketentuan">
					<p>Syarat dan Ketentuan</p>
					<i class="fal fa-angle-right"></i>
				</router-link>

				<router-link to="/tentang-rinyuakng-squad">
					<p>Tentang Rinyuakng Squad</p>
					<i class="fal fa-angle-right"></i>
				</router-link>

				<!-- <router-link to="/kebijakan-privasi">
					<p>Hubungi Kami</p>
					<i class="fal fa-angle-right"></i>
				</router-link> -->
			</div>

			<button @click="logout" v-if="token" type="button" class="btn-logout mt-5">Logout</button>
		</div>

		<Nav></Nav>
	</div>
</template>

<script>
import Nav from '@/components/UserNav.vue'
import axios from 'axios'

export default {
	components: {
		Nav
	},
	data() {
		return {
			token: localStorage.getItem('token'),
			me: '',
			edit: false
		}
	},
	methods: {
		getData() {
			axios.get(`auth/me?token=${this.token}`)
				.then(res => {
					this.me = res.data;
				})
				.catch(err => {
					// this.$router.push('/login');
				}); 
		},
		updateProfile() {
			axios.put(`auth/update?token=${this.token}`, this.me)
				.then(res => {
					this.edit = false;

					this.getData();
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		},
		updateProfilePicture() {
			const form = document.getElementById('form');
			const formData = new FormData(form);
			formData.append('_method', 'PUT');

			axios.post(`auth/updatePicture?token=${this.token}`, formData)
				.then(res => {
					this.getData();
				})
				.catch(err => {
					alert(err.response.data.message);
				}); 
		},
		logout() {
			axios.get(`auth/logout?token=${this.token}`)
				.then(res => {
					localStorage.removeItem('token');
					localStorage.removeItem('role');

					this.$router.push('/');
				})
				.catch(err => {
					alert(err.response.data.message);
				}); 
		}
	},
	created() {
		if (this.token) this.getData();
	}
}
</script>

<style scoped>

.help {
	height: fit-content;
}

.help a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;
	color: black;
	border-bottom: 1px solid rgba(0, 0, 0, .1);
	width: calc(100% + 30px);
	margin-left: -15px;
	padding: 8px 15px;
}

.help a p {
	margin: 0;
}

.help a i {
	font-size: 20px;
	color: var(--primary);
}

h6.title {
	width: calc(100% + 30px);
	background-color: rgba(0, 0, 0, .05);
	margin-left: -15px;
	padding: 10px 15px;
	margin-bottom: 0;
}

.a-edit {
	position: absolute;
	top: 60px;
	right: 15px;
	color: black;
	cursor: pointer;
}

.image-area {
	height: 200px;
	margin: 0;
	background-color: var(--primary);
	position: relative;
	display: flex;
	justify-content: center;
}

.profile {
	transform: translateY(50%);
	border-radius: 50%;
	border: 8px solid white;
	box-shadow: 0 0 0 8px var(--primary);
	overflow: hidden;
	width: 180px;
	height: 180px;
}

.edit-icon {
	position: absolute;
	z-index: 1;
	left: 65%;
	bottom: -20%;
	width: 40px;
	height: 40px;
	font-size: 1.2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: white;
	box-shadow: 0 0 5px lightgray;
	cursor: pointer;
}

.name, .email, .phone {
	margin: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.loggedin .button {
	width: 50%;
	transform: translateX(50%);
}

.edit-field {
	padding: 0;
	border: none;
	border-bottom: 2px solid var(--primary);
	outline: none;
	text-align: right;
}

.btn-logout {
	border: 1px solid gray;
	width: 100%;
	padding: .5em 1em;
	background-color: white;
}

</style>